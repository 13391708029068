import axios from "axios"
import { validarAPIKey } from "../helpers/helper.rendering"

// constantes
const dataInicial = {
  name: "Template",
  short_name: "template",
  base_url: "/",
  client_id: 6,
  // base_url:'/clientes/agostina-filo/',
  social: {
    instagram: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    youtube: "",
  },
  investor: {},
  contact: {
    phone: ["", ""],
    whatsapp: { link: "", visual: "" },
    mail: "",
  },
  location: {
    address: "",
    region: "",
    cp: "",
  },
  keys: {
    // captcha:'6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi', Mediahaus
    captcha: "6Lfmez4dAAAAAPRBJgBY1CDZNZIBBCks2ZaIy-M6",
    tokko: "",
    // tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
    google_tgm: "",
    google_map: "",
    mediacore:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMDUyNjM3NjVjYTVmZDFlMDcyNDgzYmI2YzYzZmRjNzVmYTAxMDI1OGFjZTlkMTk4ZWRhMWFjYTdiNWQyZWY0YWUyOWIyYjAxMTNiYWJjYmQiLCJpYXQiOjE2NDk0MzIxNTEsIm5iZiI6MTY0OTQzMjE1MSwiZXhwIjoxNjgwOTY4MTUxLCJzdWIiOiIyOSIsInNjb3BlcyI6WyIqIl19.PnxUvXqOGsl3YtgVZqmeZ5HGmu8g_7AXFAAeaER-97FML1nnOIe_yJ6mD2v0Z8H0jr_E1KcFO6zchdIPThkuMRG4a23fa9h9RiREUeYTxWbIdeoHB_ja-OHW7QrexgYGfc9QGKndacelyY3OW3tvBV0w7o6Es_kN5ApoQC-Y4l1rVb7yCSD8eDYla4JHzjJmJ1fhEikvL-GYo3mb94Ff3EtbKO7YACGCAJX8YkD2VuhzVJQbqHHjWget-joPdkzusiJZzcuOYeJXAEWQw_JvhEKsfFcy8r6HZ_jZp9iHUQt1JJn77qtp0G7be090xsCBKtkqimb60dueXrVM4FYuaQaeleJjTJ0DN-Vm9j6Gz4Vma8EDbrYv2gLdoAoU_Yhm9eKsY0kd46VwHNpv1tBkDoh99mepv3sBa-eh8L0S3b9sm947YelXj4u-smpAy-gvPN2aNwt9AaZi_6z8vf4uUL46NEG1RuV3BS8n4QPZGl9Jj5Fx16g6ndNKF7Qwzi6W3MFOZusTf9tLmmvOS84Yq_Qb0T1tPLDG5DCE9XCCs5WYb5ZW92uLJEKAJhsLafqEu27e0VIOAtYsqRagRHFIzno-6WvbtQggNglr_TxS4eE7RSVQeJBW_z3ztZb-1c0tAP8I8pmpMcx_ePlOXuD-etKVXD_bYhK9O2NvZBXLzo4",
  },
  seo: {
    title: "template",
    description: "",
    url: "",
    img: "",
    image_url: "",
    site_name: "",
    locale: "",
    type: "website",
  },
  routes:
    '{"SALE_VIEW_DATA": {"name": "Venta", "route":"venta"}, "RENT_VIEW_DATA": {"name": "Alquiler", "route":"alquiler"}, "TEMPORARY_VIEW_DATA" : {"name": "Temporario", "route":"temporario"}}',
  modules: [],
  template_data:{
    APISERVER:'',
    CLIENTID:'',
  }
}

const GET_INVESTOR = "GET_INVESTOR"
const CHANGE_API = "CHANGE_API"
const CHANGE_MAP = "CHANGE_MAP"
const CHANGE_ROUTE = "CHANGE_ROUTE"
const GET_CONFIGURATIONS = "GET_CONFIGURATIONS"
const CHANGE_TEMPLATE_DATA = "CHANGE_TEMPLATE_DATA"

export default function settingsReducer(state = dataInicial, action) {
  switch (action.type) {
    case GET_INVESTOR: {
      return { ...state, investor: action.payload }
    }
    case CHANGE_API: {
      return { ...state, keys: { ...state.keys, tokko: action.payload } }
    }
    case CHANGE_TEMPLATE_DATA: {
      return { ...state, 
        template_data: action.payload }
    }
    case GET_CONFIGURATIONS: {
      return { ...state, modules: action.payload }
    }
    case CHANGE_MAP: {
      return { ...state, keys: { ...state.keys, google_map: action.payload } }
    }
    default:
      return state
  }
}

// 2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b

export const GET_INVESTOR_ACTION = env => async (dispatch, getState) => {
  const { APISERVER, CLIENTID } = env
  try {
    const res = await axios.get(
      `${APISERVER}/investor/configuracion_usuarios/publica/?client=${CLIENTID}`
    )
    dispatch({
      type: GET_INVESTOR,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}
export const CHANGE_DATA_TEMPLATE_ACTION = env => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_TEMPLATE_DATA,
    payload: env,
  })
}
export const CHANGE_APIKEY_ACTION = api_key => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_API,
    payload: api_key,
  })
}
export const CHANGE_MAPKEY_ACTION = map_key => async (dispatch, getState) => {
  if(validarAPIKey(map_key)){
    dispatch({
      type: CHANGE_MAP,
      payload: map_key,
    })
  }
}
export const CHANGE_ROUTES_ACTION = routes => async (dispatch, getState) => {
  if (routes) {
    dispatch({
      type: CHANGE_ROUTE,
      payload: routes,
    })
  }
}
export const GET_CONFIGURATIONS_ACTION = env => async (dispatch, getState) => {
  const { APISERVER, CLIENTID } = env
  try {
    const res = await axios.get(
      `${APISERVER}/core/custom-login/configuraciones/?client=${CLIENTID}`
    )
    dispatch({
      type: GET_CONFIGURATIONS,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
  //
}
