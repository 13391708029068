import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import { compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {loadDevTools} from './reduxDevTools'
import { store } from './store'
// const composeEnhancers = typeof(window) === 'undefined' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;
const composeEnhancers = compose;

// const createStore = () => reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default ({ element }) => (
  <>
    <Provider store={store}>{element}</Provider>
  </>
);  