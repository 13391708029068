import { forEach } from "async"

export const adapt_property_data = response => {
  const data = response
  data.surface = data.surface.toString()
  data.is_starred_on_web = data.featured
  data.total_surface = data.total_surface.toString()
  data.roofed_surface = data.roofed_surface.toString()
  data.semiroofed_surface = data.semiroofed_surface.toString()
  data.web_price = data.show_price
  data.fake_address = data.address // Es address?
  data.real_address = data.address // Es address?
  const extended_location = Object.assign({}, data.location, {
    country: data?.location?.country,
    city: data?.location?.city,
    state: data?.location?.state,
    neighborhood: data?.location?.neighborhood,
    name: `${data?.location?.neighborhood || data?.location?.city}`,
  })
  data.location = extended_location
  data.type = { name: data.property_type[0] || "Sin Especificar" }
  // data.operations = data.operations.filter(op => op?.prices?.price !== 0)
  if (data?.development !== null) {
    if (data?.development?.is_active) {
      const converted = Object.assign({}, data.development, {
        display_on_web: true,
      })
      // data.development = (converted)
      data.development = adapt_development_data(converted)
    } else {
      const converted = Object.assign({}, data.development, {
        display_on_web: false,
      })
      // data.development = (converted)
      data.development = adapt_development_data(converted)
    }
  }

  data.tags = [...data.extra_services.map(element => ({ name: element,tag_type:1 }))]
  data.tags = [
    ...data.tags,
    ...data.extra_amenities.map(element => ({ name: element, tag_type:2})),
  ]
  data.tags = [
    ...data.tags,
    ...data.extra_general.map(element => ({ name: element, tag_type:3})),
  ]

  data.files = data?.files?.map(file => ({ file: file.original, public_name : file.public_name }))
  data.photos = [
    ...data.photos,
    ...data?.blueprints?.map(b => ({ ...b, is_blueprint: true })),
  ]
  data.videos = [
    ...response.videos,
    ...response.tour_360.map(b => ({
      ...b,
      is_tour: true,
      player_url: b?.url,
      provider: b?.platform?.toLowerCase(),
    })),
  ]
  data.videos = data.videos.map(v => ({
    ...v,
    provider: v?.platform?.toLowerCase(),
    player_url: v?.url,
  }))
  return data
}

export const adapt_development_data = response => {
  let data = response
  data.custom_tags = []
  data.is_starred_on_web = response.featured
  const extended_location = Object.assign({}, response.location, {
    name: `${response?.location?.neighborhood || response?.location?.city}`,
    full_location:`${response.location?.country} | ${response.location?.state} | ${response.location?.city} | ${response.location?.neighborhood}`
  })
  data.location = extended_location
  // data.location.full_location = `${response.location.country} | ${response.location.state} | ${response.location.city} | ${response.location.neighborhood}`
  data.fake_address = response.address
  const type_converted = Object.assign({}, response.type, {
    name:
      response.property_type.length > 0
        ? response.property_type[0]
        : "Sin asignar",
  })
  data.tags = [...data.extra_services.map(element => ({ name: element,tag_type:1 }))]
  data.tags = [
    ...data.tags,
    ...data.extra_amenities.map(element => ({ name: element, tag_type:2})),
  ]
  data.tags = [
    ...data.tags,
    ...data.extra_general.map(element => ({ name: element, tag_type:3})),
  ]

  data.type = type_converted
  data.construction_date = response.finish_date
  data.publication_title = response.commercial_description
  data.files = response.files.map(file => ({ file: file.original,public_name : file.public_name  }))
  data.photos = [
    ...response.photos,
    ...response.blueprints.map(b => ({ ...b, is_blueprint: true })),
  ]
  data.videos = [
    ...response.videos,
    ...response.tour_360.map(b => ({
      ...b,
      is_tour: true,
      player_url: b?.url,
      provider: b?.platform?.toLowerCase(),
    })),
  ]
  data.videos = data.videos.map(v => ({
    ...v,
    provider: v.platform,
    player_url: v?.url,
  }))
  // data.properties = data.properties !== undefined ? response?.properties?.map((p) => adapt_property_data(p)) : []
  return data
}

export const adapt_unities_data = response => {
  const unities = response
    .map(r => ({
      ...r,
      group: `${r.subdivision} ` + r.subdivision_number,
    }))
    .filter(u => u.subdivision_number !== "")

  let groups = []

  for (let index = 0; index < unities.length; index++) {
    const unity = unities[index]
    const group_find = groups.find(g => g.floor === unity.group) // Me fijo si existe un grupo igual al de esta unidad
    if (group_find) {
      for (let index = 0; index < groups.length; index++) {
        const group = groups[index]
        if (group.floor === unity.group) {
          group.unities.push(adapt_unity_to_table(adapt_property_data(unity)))
        }
      }
    } else {
      groups.push({
        floor: unity.group,
        unities: [adapt_unity_to_table(adapt_property_data(unity))],
      })
    }
  }

  groups.unities = groups.map(group =>
    group.unities.sort((a, b) => a.address?.localeCompare(b.address))
  )

  return groups.sort(
    (a, b) => a?.floor?.split(" ")[1] - b?.floor?.split(" ")[1]
  )
}

export const adapt_unity_to_table = unity => {
  return {
    ...unity,
    address: unity.apartment || unity.address,
    real_address: unity.apartment || unity.address,
    rooms: unity.room_amount,
    surface: unity.surface.toString(),
    surface_total: unity.total_surface.toString(),
  }
}

export const adapt_summary_data = response => {
  return {
    ...response,
    property_types: response.property_types.sort((a,b) => b.count - a.count),
    locations_tree:parseNameLocations(response),
    locations: response.locations.map((loc, index) => ({ ...loc,location_name:loc.location_name.normalize('NFD').replace(/[\u0300-\u036f]/g,""), location_id: index })).sort((a,b) => b.count - a.count),
  }
}

const parseNameLocations = response => {
  if(response.locations_tree){
    for (let a = 0; a < response.locations_tree.length; a++) {
      response.locations_tree[a].name = response.locations_tree[a].name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
      for (let b = 0; b < response.locations_tree[a].data.length; b++) {
        response.locations_tree[a].data[b].name = response.locations_tree[a].data[b].name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
      }
    }
    //   locations.push({...country,name:country.name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")})
    //   // .replace(/[^a-zA-Z0-9]/g, '')
    //   for (const state of country.data) {
    //     locations.push({...state,name:state.name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")})
    //     // .replace(/[^a-zA-Z0-9]/g, '')
    //     for (const city of state.data) {
    //       locations.push({...city,name:city.name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")})
    //       // .replace(/[^a-zA-Z0-9]/g, '')
    //       for (const neighborhood of city.data) {
    //         locations.push({...neighborhood,name:neighborhood.name.normalize('NFD').replace(/[\u0300-\u036f]/g,"")})
    //         // .replace(/[^a-zA-Z0-9]/g, '')
    //       }
    //     }
    //   }
  }
  console.log(response.locations_tree)
  return response.locations_tree
}